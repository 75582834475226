import React, { useEffect, useState } from 'react'
import { translate } from 'react-i18next'
import Languages from './languages/_index'

import Check from '@interco/icons/build-v4/orangeds/LG/check'
import ChevronDown from '@interco/icons/build-v4/orangeds/LG/chevron-down'

import iconBrazil from './images/icon-brazil.svg'
import iconUSA from './images/icon-usa.svg'

import { Country, OpenLang } from './style'

interface IFooterProps {
  t: (values: string) => string;
  theme?: string;
  ptURL: string;
  enURL: string;
  i18n: { language: string };
}

type ListProps = {
  country: string;
  icon?: React.ReactNode;
  urlPage: string;
}

const listLang = [
  {
    country: 'Brasil',
    icon: <img src={iconBrazil} alt='Brazil flag' />,
    urlPage: '/',
  },
  {
    country: 'USA',
    icon: <img src={iconUSA} alt='USA flag' />,
    urlPage: 'https://us.inter.co/',
  },
]

const PagesLanguages = (props: IFooterProps) => {
  const [ openLang, setOpenLang ] = useState(false)
  const [ active, setActive ] = useState(true)
  const [ isIndex, setIsIndex ] = useState(props.i18n.language === 'pt' ? 0 : 1)
  const [ isList, setIsList ] = useState(listLang[isIndex])

  const HandleClick = (index: number) => {
    setIsIndex(index)
    isIndex === index && setActive(true)
  }

  useEffect(() => {
    setIsList(isList)
  }, [ isIndex, props.i18n.language, isList ])

  const [ pageHaveEnglishContent, setPageHaveEnglishContent ] = useState(true)

  useEffect(() => {
    if (!window.location.href.includes('/en/') && !props.enURL) {
      setPageHaveEnglishContent(false)
    }
  }, [])

  return (
    <>
      <Country
        theme={props.theme}
        className='d-flex align-items-lg-center justify-content-lg-between justify-content-xl-start flex-column flex-lg-row mb-4'
      >
        <div className='col-12 col-lg-8 col-xl-7 px-lg-0'>
          <p className='fs-14 lh-17 mb-lg-0 mr-xl-4'>
            {props.t('country')}
          </p>
        </div>
        <div className='col-12 col-lg-4 position-relative'>
          <div key={isList.country} className='d-flex align-items-center justify-content-start'>
            <div className='lang'>
              {isList.icon}
            </div>
            <span className='fs-16 lh-20 fw-600' onClick={() => setOpenLang(!openLang)}>
              {isList.country}
            </span>
            <div
              onClick={() => {
                setOpenLang(!openLang)
                setActive(!active)
                HandleClick(isIndex)
              }}
              className='cursor-pointer'
            >
              <ChevronDown height={24} width={24} color={props.theme === 'dark' ? '#FFFFFF' : '#161616'} />
            </div>
          </div>
          {openLang &&
            <OpenLang>
              {listLang.map((item: ListProps, index: number) => (
                <div key={item.country} className='col-12 px-0 d-flex align-items-center justify-content-between list'>
                  <div className='lang'>
                    {item.icon}
                  </div>
                  <a
                    href={item.urlPage}
                    onClick={() => {
                      setOpenLang(!openLang)
                      HandleClick(index)
                    }}
                  >
                    <span className={`fs-16 lh-20 fw-600 ${active && isIndex === index ? 'text-orange--extra' : ''}`}>{item.country}</span>
                  </a>
                  <Check height={24} width={24} color={active && isIndex === index ? '#ff7a00' : '#FFFFFF'} />
                </div>
                ))}
            </OpenLang>
          }
        </div>
      </Country>
      {pageHaveEnglishContent && (
        <Languages
          theme={props.theme}
          ptURL={props.ptURL}
          enURL={props.enURL}
        />)
      }
    </>
  )
}

export default translate('Footer')(PagesLanguages)
