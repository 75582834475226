import React from 'react'
import { translate } from 'react-i18next'
import useWidth from 'src/hooks/window/useWidth'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerFooter'

import SocialNetwork from 'components/SocialNetwork'
import AtendimentoLibrasDark from 'src/assets/images/svg/icon-atendimento-libras-dark.svg'
import AtendimentoLibrasLight from 'src/assets/images/svg/icon-atendimento-libras-light.svg'
import Link from 'src/components/GatsbyLinkWrapper'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import DisplayStore from './DisplayStore'
import Pagelanguages from './Pagelanguages/_index'
import PrivacyCertification from './PrivacyCertification'

import { WIDTH_LG } from 'src/styles/breakpoints'
import { WIDTH_MD } from 'src/utils/breakpoints'
import { IFooterProps } from './types'

const FooterPT = (props: IFooterProps) => {
  const width = useWidth()
  const [ sendDatalayerFooterEvent ] = useDataLayer()

  return (
    <div id='footer' className='container'>
      <div className='row'>
        { width >= WIDTH_LG &&
          <div className='col-12 pr-lg-0'>
            <div className='col-12 px-lg-0 pb-lg-4'>
              <PrivacyCertification />
            </div>
            <div className='col-12 px-lg-0'>
              <DisplayStore />
            </div>
            <div className='col mt-lg-4 px-lg-0'>
              <SocialNetwork />
            </div>
            { width <= WIDTH_MD &&
              <div className='col mt-lg-4 px-lg-0 language'>
                <Pagelanguages
                  theme={props.theme}
                  slug={props.slug}
                  pagePT={props.pagePT}
                  pageEN={props.pageEN}
                  isHeaderUS={props.isHeaderUS}
                  ptURL={props.ptURL}
                  enURL={props.enURL}
                />
              </div>
            }
          </div>
         }
        <div className='col-12 for-you'>
          <div className='col-12 mb-3 pr-5 pr-md-0 pl-0'>
            <span className='title-font d-block fs-16 fw-700 mb-3'>{props.t('help_center.products.title')}</span>
            <ul className='list-unstyled'>
              <li data-footer='o-inter'>
                <Link
                  to='/o-inter/'
                  title={props.t('help_center.products.about')}
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: props.t('help_center.products.title'),
                      element_action: 'click button',
                      element_name: props.t('help_center.products.about'),
                      redirect_url: `${window.location.origin}/o-inter/`,
                      c_page: window.location.href,
                    })
                  }}
                >{props.t('help_center.products.about')}
                </Link>
              </li>
              <li data-footer='portal-ri'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: props.t('help_center.products.title'),
                      element_action: 'click button',
                      element_name: props.t('help_center.products.portal'),
                      redirect_url: 'https://investors.inter.co/',
                      c_page: window.location.href,
                    })
                  }}
                  to='https://investors.inter.co/'
                  title={props.t('help_center.products.portal')}
                >{props.t('help_center.products.portal')}
                </Link>
              </li>
              <li data-footer='etica-e-compliance'>
                <Link
                  to='/etica-e-compliance/'
                  title={props.t('help_center.products.ethic')}
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: props.t('help_center.products.title'),
                      element_action: 'click button',
                      element_name: props.t('help_center.products.ethic'),
                      redirect_url: 'https://inter.co/etica-e-compliance/',
                      c_page: window.location.href,
                    })
                  }}
                >{props.t('help_center.products.ethic')}
                </Link>
              </li>
              <li data-footer='inter-investimentos'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: props.t('help_center.products.title'),
                      element_action: 'click button',
                      element_name: props.t('help_center.products.investiments'),
                      redirect_url: `${window.location.origin}/pra-voce/investimentos/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/pra-voce/investimentos/'
                  title={props.t('help_center.products.investiments')}
                >{props.t('help_center.products.investiments')}
                </Link>
              </li>
              <li data-footer='blog'>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: props.t('help_center.products.title'),
                      element_action: 'click button',
                      element_name: props.t('help_center.products.blog'),
                      redirect_url: 'https://blog.inter.co/',
                      c_page: window.location.href,
                    })
                  }}
                  href='https://blog.inter.co/'
                  title={props.t('help_center.products.blog')}
                >{props.t('help_center.products.blog')}
                </a>
              </li>
              <li data-footer='newsroom'>
                <Link
                  to='/newsroom/'
                  title={props.t('help_center.products.newsroom')}
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: props.t('help_center.products.newsroom'),
                      element_action: 'click button',
                      element_name: props.t('help_center.products.newsroom'),
                      redirect_url: `${window.location.origin}/newsroom/`,
                      c_page: window.location.href,
                    })
                  }}
                >
                  Newsroom
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className='col-12 need-help'>
          <div className='col-12 mb-3 pr-md-0 pl-0'>
            <span className='title-font d-block fs-16 fw-700 mb-3'>{props.t('quick_access.title')}</span>
            <ul className='list-unstyled'>
              <li data-footer='central-de-ajuda'>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: props.t('quick_access.title'),
                      element_action: 'click button',
                      element_name: props.t('help_center.title'),
                      redirect_url: 'https://ajuda.inter.co/',
                      c_page: window.location.href,
                    })
                  }}
                  href='https://ajuda.inter.co/' target='_blank' rel='noreferrer'
                  title={props.t('help_center.title')}
                >{props.t('help_center.title')}
                </a>
              </li>
              <li data-footer='canais-de-atendimento'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: props.t('quick_access.title'),
                      element_action: 'click button',
                      element_name: props.t('help_center.call_center'),
                      redirect_url: `${window.location.origin}/canais-de-atendimento/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/canais-de-atendimento/'
                  title={props.t('help_center.call_center')}
                >{props.t('help_center.call_center')}
                </Link>
              </li>
              <li data-footer='ouvidoria'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: props.t('quick_access.title'),
                      element_action: 'click button',
                      element_name: props.t('help_center.ombudsman'),
                      redirect_url: `${window.location.origin}/ouvidoria/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/ouvidoria/'
                  title={props.t('help_center.ombudsman')}
                >
                  {props.t('help_center.ombudsman')}
                </Link>
              </li>
            </ul>
          </div>
          <div className='col-12 mb-3 pl-0'>
            <ul className='list-unstyled'>
              <li data-footer='segunda-via-do-boleto'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: props.t('quick_access.title'),
                      element_action: 'click button',
                      element_name: props.t('quick_access.second_billet'),
                      redirect_url: `${window.location.origin}/segunda-via-boleto/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/segunda-via-boleto/'
                  title={props.t('quick_access.second_billet')}
                >
                  {props.t('quick_access.second_billet')}
                </Link>
              </li>
              <li data-footer='saldo-devedor'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: props.t('quick_access.title'),
                      element_action: 'click button',
                      element_name: props.t('quick_access.balance'),
                      redirect_url: `${window.location.origin}/saldo-devedor/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/saldo-devedor/'
                  title={props.t('quick_access.balance')}
                >{props.t('quick_access.balance')}
                </Link>
              </li>
              <li data-footer='negocie-sua-divida'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: props.t('quick_access.title'),
                      element_action: 'click button',
                      element_name: props.t('quick_access.negotiate'),
                      redirect_url: `${window.location.origin}/negocie/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/negocie/'
                  title={props.t('quick_access.negotiate')}
                >{props.t('quick_access.negotiate')}
                </Link>
              </li>
              <li data-footer='leilao-de-carteiras-de-credito'>
                <Link
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: props.t('quick_access.title'),
                      element_action: 'click button',
                      element_name: props.t('quick_access.auctions'),
                      redirect_url: `${window.location.origin}/leilao-de-carteiras-de-credito/`,
                      c_page: window.location.href,
                    })
                  }}
                  to='/leilao-de-carteiras-de-credito/'
                  title={props.t('quick_access.auctions')}
                >{props.t('quick_access.auctions')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className='col-12 contact'>
          <div className='col-12 pr-md-0 pl-0'>
            <span className='title-font  d-block fs-16 fw-700 mb-3'>{props.t('help_center.contact')}</span>
            <ul className='list-unstyled list-phones'>
              <li>
                {props.t('help_center.capital_cities')}
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: 'Nossos números',
                      element_action: 'click button',
                      element_name: `${props.t('help_center.capital_cities')} - 3003 4070`,
                      redirect_url: 'tel:30034070',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:30034070'
                >3003 4070
                </a>
              </li>
              <li>
                {props.t('help_center.other_cities')}
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: 'Nossos números',
                      element_action: 'click button',
                      element_name: `${props.t('help_center.other_cities')} - 0800 940 0007`,
                      redirect_url: 'tel:08009400007',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:08009400007'
                >0800 940 0007
                </a>
              </li>
              <li>
                {props.t('help_center.sac')}
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: 'Nossos números',
                      element_action: 'click button',
                      element_name: `${props.t('help_center.sac')} - 0800 940 9999`,
                      redirect_url: 'tel:08009409999',
                      c_page: window.location.href,
                    })
                  }}
                  href='tel:08009409999'
                >0800 940 9999
                </a>
              </li>
            </ul>
          </div>
          <div className='col-12 mb-3 px-0'>
            <ul className='list-unstyled list-phones'>
              <li>
                {props.t('help_center.ombudsman')}
                <a
                  href='tel:08009407772'
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: 'Nossos números',
                      element_action: 'click button',
                      element_name: `${props.t('help_center.ombudsman')} - 0800 940 7772`,
                      redirect_url: 'tel:08009407772',
                      c_page: window.location.href,
                    })
                  }}
                >0800 940 7772
                </a>
              </li>
              <li>
                {props.t('help_center.official_letter')}
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: 'Nossos números',
                      element_action: 'click button',
                      element_name: `${props.t('help_center.official_letter')} - oficios@bancointer.com.br`,
                      redirect_url: 'mailto:oficios@bancointer.com.br',
                      c_page: window.location.href,
                    })
                  }}
                  href='mailto:oficios@bancointer.com.br'
                >oficios@bancointer.com.br
                </a>
              </li>
              <li className='fs-10 lh-12 d-none d-md-block' dangerouslySetInnerHTML={{ __html: props.t('disclamer') }} />
              <li className='fs-10 lh-12 d-flex align-items-center fw-600'>
                { props.theme === 'dark' ? <img src={AtendimentoLibrasLight} aria-hidden /> : <img src={AtendimentoLibrasDark} aria-hidden /> }
                <a
                  href='/atendimento-em-libras/?utm_source=site&utm_medium=rodape'
                  className='ml-1'
                  title={props.t('service_in_pounds')}
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: 'Nossos números',
                      element_action: 'click button',
                      element_name: props.t('service_in_pounds'),
                      redirect_url: '/atendimento-em-libras/?utm_source=site&utm_medium=rodape',
                      c_page: window.location.href,
                    })
                  }}
                > {props.t('service_in_pounds')}
                </a>
              </li>
            </ul>
          </div>
        </div>
        {width < WIDTH_MD &&
          <div className='col-12 pb-4 disclamer'>
            <p className='mb-0' dangerouslySetInnerHTML={{ __html: props.t('disclamer') }} />
          </div>
        }
      </div>
      <div className='row'>
        <div className='col-12 mb-4 mb-lg-0'>
          <div className='row d-md-flex'>
            {width <= WIDTH_MD &&
              <div className='col-12 col-md-6 pt-4 pt-md-0'>
                <PrivacyCertification />
              </div>
            }
            <div className='col-12 col-md-6 language'>
              <Pagelanguages
                theme={props.theme}
                slug={props.slug}
                pagePT={props.pagePT}
                pageEN={props.pageEN}
                isHeaderUS={props.isHeaderUS}
                ptURL={props.ptURL}
                enURL={props.enURL}
              />
            </div>
          </div>
        </div>
        {width <= WIDTH_MD &&
          <div className='col-12'>
            <div className='row d-md-flex'>
              <div className='col-12 col-md-6'>
                <DisplayStore />
              </div>
              <div className='col-12 col-md-6'>
                <SocialNetwork />
              </div>
            </div>
          </div>
        }
      </div>
      <div className='col-12 pb-md-4 pb-lg-2 border-md-bottom' />
      <div className='row sub-footer'>
        <div className='col-12 text-left'>
          <ul className='list-inline fs-10'>
            <li className='list-inline-item' data-footer='trabalhe-com-a-gente'>
              <a
                href='https://carreiras.inter.co/'
                title='Trabalhe com a gente'
                data-footer='trabalhe-com-a-gente'
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: props.t('work_with_us'),
                    redirect_url: 'https://carreiras.inter.co/',
                    c_page: window.location.href,
                  })
                }}
              >
                {props.t('work_with_us')}
              </a>
            </li>
            <li className='list-inline-item' data-footer='cotacao-dolar'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: props.t('quick_access.dolar_value'),
                    redirect_url: `${window.location.origin}/pra-voce/cartoes/cotacao-dolar/`,
                    c_page: window.location.href,
                  })
                }}
                to='/pra-voce/cartoes/cotacao-dolar/'
                title={props.t('quick_access.dolar_value')}
              >{props.t('quick_access.dolar_value')}
              </Link>
            </li>
            <li className='list-inline-item' data-footer='tarifas'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: props.t('fares'),
                    redirect_url: `${window.location.origin}/tarifas/`,
                    c_page: window.location.href,
                  })
                }}
                to='/tarifas/'
                title={props.t('fares')}
              >
                {props.t('fares')}
              </Link>
            </li>
            <li className='list-inline-item' data-footer='seguranca'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: props.t('security'),
                    redirect_url: `${window.location.origin}/seguranca/`,
                    c_page: window.location.href,
                  })
                }}
                to='/seguranca/'
                title={props.t('security')}
              >
                {props.t('security')}
              </Link>
            </li>
            <li className='list-inline-item' data-footer='privacidade-de-dados'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: props.t('data_privacy'),
                    redirect_url: `${window.location.origin}/privacidade-de-dados/`,
                    c_page: window.location.href,
                  })
                }}
                to='/privacidade-de-dados/'
                title={props.t('data_privacy')}
              >
                {props.t('data_privacy')}
              </Link>
            </li>
            <li className='list-inline-item' data-footer='politica-de-privacidade'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: props.t('privacy_policy'),
                    redirect_url: `${window.location.origin}/politica-de-privacidade/`,
                    c_page: window.location.href,
                  })
                }}
                to='/politica-de-privacidade/'
                title={props.t('privacy_policy')}
              >
                {props.t('privacy_policy')}
              </Link>
            </li>
            <li className='list-inline-item' data-footer='canal-de-denuncia'>
              <a
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: props.t('help_center.report_channel'),
                    redirect_url: 'https://canaldeetica.com.br/interco/',
                    c_page: window.location.href,
                  })
                }}
                href='https://canaldeetica.com.br/interco/'
                rel='noreferrer'
                target='_blank'
                title={props.t('help_center.report_channel')}
              >
                {props.t('help_center.report_channel')}
              </a>
            </li>
            <li className='list-inline-item' data-footer='politica-de-seguranca-da-informacao'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: props.t('security_policy'),
                    redirect_url: `${window.location.origin}/politica-de-seguranca/`,
                    c_page: window.location.href,
                  })
                }}
                to='/politica-de-seguranca/'
                title={props.t('security_policy')}
              >
                {props.t('security_policy')}
              </Link>
            </li>
            <li className='list-inline-item' data-footer='politica-de-seguranca-da-informacao-para-fornecedores'>
              <Link
                onClick={() => {
                  sendDatalayerFooterEvent({
                    section: 'null',
                    element_action: 'click button',
                    element_name: props.t('security_policy'),
                    redirect_url: `${window.location.origin}/politica-de-seguranca-para-fornecedores/`,
                    c_page: window.location.href,
                  })
                }}
                to='/politica-de-seguranca-para-fornecedores/'
                title={props.t('security_policy_partners')}
              >
                {props.t('security_policy_partners')}
              </Link>
            </li>
          </ul>
          <address>
            <div className='d-flex align-items-center mb-3'>
              &copy; <span className='fs-10 ml-1 text-left' dangerouslySetInnerHTML={{ __html: props.t('cnpj') }} />
            </div>
            <div className='d-xl-flex'>
              <div className='d-flex mr-xl-3 mb-2 mb-xl-0'>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: 'null',
                      element_action: 'click button',
                      element_name: props.t('address_bh'),
                      redirect_url: 'https://goo.gl/maps/6ELbHe2AsaN6VzsE7',
                      c_page: window.location.href,
                    })
                  }}
                  href='https://goo.gl/maps/6ELbHe2AsaN6VzsE7'
                  className='address d-flex'
                  target='_blank'
                  rel='noreferrer'
                >
                  <OrangeIcon size='SM' icon='location' />
                  <span className='col fs-10 pl-1 text-left' dangerouslySetInnerHTML={{ __html: props.t('address_bh') }} />
                </a>
              </div>
              <div className='d-flex mr-xl-3 mb-2 mb-xl-0'>
                <a
                  onClick={() => {
                    sendDatalayerFooterEvent({
                      section: 'null',
                      element_action: 'click button',
                      element_name: props.t('address_sp'),
                      redirect_url: 'https://goo.gl/maps/dhFZGQQuaAsU8fuy9',
                      c_page: window.location.href,
                    })
                  }}
                  href='https://goo.gl/maps/dhFZGQQuaAsU8fuy9'
                  className='address d-flex'
                  target='_blank'
                  rel='noreferrer'
                >
                  <OrangeIcon size='SM' icon='location' />
                  <span className='col fs-10 pl-1 text-left' dangerouslySetInnerHTML={{ __html: props.t('address_sp') }} />
                </a>
              </div>
            </div>
          </address>
        </div>
      </div>
    </div>
  )
}

export default translate('Footer')(FooterPT)
